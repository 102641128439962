
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.s-values {
  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    &-value {
      flex: 1;
      ::v-deep .esmp-input .esmp-input-icon {
        color: $color-grayscale-60;
      }
      &--has-groups {
        ::v-deep .esmp-input .esmp-input-icon {
          color: $color-primary-1-day;
        }
      }
    }
    &-actions {
      flex-shrink: 0;
      margin-top: 10px;
      margin-left: 10px;
      &__item {
        transition: color $base-animation;
        &:hover {
          cursor: pointer;
          color: $color-primary-1-day;
        }
      }
    }
  }

  &__connected-groups {
    margin-left: 10px;
    margin-bottom: 10px;
    &__item {
      margin-top: 8px;
      ::v-deep .esmp-checkbox-group-item {
        margin-top: 4px;
      }
    }
  }

  &__actions {
    margin-top: 20px;
    &-loader {
      display: inline-block;
    }
    &-item + &-item {
      margin-top: 10px;
    }
  }
}

